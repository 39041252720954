import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Index from "../pages/index";
import Ad from "../pages/weekly-ad";
import CouponHub from "../pages/couponHub";

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/weekly-ad" element={<Ad />} />
        <Route path="/coupon-hub" element={<CouponHub />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
