import Footer from "../partials/footer";
import Header from "../partials/header";
//import WeeklyAd from '../components/weeklyAd';
import React from "react";
import "../styles/index.scss";
//import "../fonts/nexa-black.otf";
import { useEffect } from "react";

function Ad() {

  useEffect(() => {
    const head = document.querySelector("head");
    const script = document.createElement("script");

    script.setAttribute(
      "src",
      "https://pr1stddemoblob17263.blob.core.windows.net/public/digitaladcdn/pr-digitalad-main.js"
    );
    head.appendChild(script);

    return () => {
      head.removeChild(script);
    };
  }, []);

  return (
    <div className="Ad">
      <Header></Header>
      <pr-digital-ad api-key="pgH7QzFHJx4w46fI~5Uzi4RvtTwlEXp1"></pr-digital-ad>
      <Footer></Footer>
    </div>
  );
}

export default Ad;