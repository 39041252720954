/* import React, { useState } from 'react'; */
import './../styles/_modalSlide.scss';

const SlideModalTemplate = ({ show, onClose, }) => {

	return (
		<section className={show ? 'sidenav active bot' : 'sidenav bot'} >
			<div onClick={(e) => {
				e.stopPropagation()
			}} className='modal-cnt col-md-12 col-12'>
				<div className='close-modal-slide'>
					<span onClick={onClose}>
						<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M14.5788 2.57294C15.1343 1.98471 15.1343 1.02941 14.5788 0.441176C14.0232 -0.147059 13.121 -0.147059 12.5654 0.441176L7.88544 5.40118L3.201 0.445882C2.64544 -0.142353 1.74322 -0.142353 1.18766 0.445882C0.632107 1.03412 0.632107 1.98941 1.18766 2.57765L5.87211 7.53294L1.19211 12.4929C0.636552 13.0812 0.636552 14.0365 1.19211 14.6247C1.74766 15.2129 2.64989 15.2129 3.20544 14.6247L7.88544 9.66471L12.5699 14.62C13.1254 15.2082 14.0277 15.2082 14.5832 14.62C15.1388 14.0318 15.1388 13.0765 14.5832 12.4882L9.89877 7.53294L14.5788 2.57294Z" fill="black" fillOpacity="0.6" />
						</svg>
					</span>
				</div>
				<div className='content'>
					<h3 className='title-3-2-2 text-center title-slide pb-1'>Welcome to Swaggy's!</h3>
					<p className='p-modal text-center pb-2'>Join the <span className='red-spam'>Swaggy's Savings Club</span> today and enjoy special perks
						and exclusive weekly deals delivered to your inbox.</p>
				</div>
				<div className='content-box'>
					<span className='p-box-slide-firts'>10% OFF </span>
					<span className='p-box-slide-second'>your first order</span>
				</div>
				<div className='btn-slide-cont'>
					<button className='button-blue'>
						<span className='bold'>Join the Club</span>
					</button>
				</div>
				<span onClick={onClose} className="underlined-link-slide" style={{ 'cursor': 'pointer' }}>No thanks</span>
			</div>
		</section>
	)
}

export default SlideModalTemplate;

