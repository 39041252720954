import { React, useRef, useState } from "react";
import BoxTemplateComponent from './BoxTemplateComponent';

// import Swiper core and required modules
import { Navigation, Pagination, A11y, Mousewheel } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import ProductOffer from '../api/productOfferapi';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/mousewheel';
import arrow from "./../images/lightblue_arrow.svg"

//GSAP
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

  const handleClick = () => {
    window.open('/weekly-ad', '_self');
  }

function TopOffers() {
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '"></span>';
    },
  };


  const listProducts = [];
 
  listProducts.push(ProductOffer('3135040d-0ad2-42b2-b625-33377e493f4e'), 
  ProductOffer('20ebb031-304d-4069-bf6a-92cd2029e129'), ProductOffer('af6cc6fb-52ab-4893-a603-2350bb514304'), ProductOffer('3f3dc416-e8a6-42cc-b165-d00bf378b8ac'), ProductOffer('050f4b85-9b5e-476d-86e6-0615253abc3c'), ProductOffer('5dbdc054-4414-4c63-95f4-720237158783'), ProductOffer('01ab56d9-af6e-4993-918b-eac4333d570b'), ProductOffer('2d416d1b-7412-4d79-9633-6a7426235a1d'), ProductOffer('6519005a-4c7f-48e5-bf43-b6170e429cf7'));

  //console.log(listProducts, 'testb');
  const [animIsPlaying, setAnimIsPlaying] = useState(false);
 const containerRef = useRef();
  useGSAP(
    () => {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".container",
          start: "-80% top",
          //markers: true,
        },
      });
      tl.from(".animated-text", {
        autoAlpha: 0,
        y: 20,
        stagger: { each: 0.3 },
      }).from(".animated-inner-text", {
        autoAlpha: 0,
        y: 20,
        stagger: { each: 0.3 },
        //onComplete: () => setVIDEOIsPlaying(true),
      });
    },
    { scope: containerRef }
  );

    return (
      <>
        <section className='topOffers-content' ref={containerRef}>
          <div className='container'>
            <h2 className="title-2 reverse animated-text">Our Top Offers</h2>
            <h3 className="large-body animated-text">Swaggy’s Super Savers <br></br>Your wallet’s gonna love these.</h3>
            <div className='container-epic my-5'>
                <div className='row'>
                  <div className='col-lg-6'>
                    <iframe src="https://player.vimeo.com/video/922647012?h=0f26f1fb8c" 
                    className="video-box"
                    frameBorder="0" 
                    allow="autoplay; fullscreen; picture-in-picture" 
                    allowFullScreen>
                    </iframe>
                  </div>
                  <div className='col-lg-6'>
                      <div className='container-right'>
                          <h4 className='title-2 animated-inner-text'>Today's Big Deal</h4>
                          <p className='large-body animated-inner-text'>Epic bargains, refreshed daily</p>
                          <button onClick={handleClick} type="button" className='button-blue'>Shop now</button>
                        </div>
                  </div>
                </div>
            </div>

            <Swiper
              // install Swiper modules
              modules={[Navigation, Pagination, A11y, Mousewheel]}
              spaceBetween={0}
              slidesPerView={2}
              pagination={pagination}
              mousewheel={true}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 0,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 5,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
                1200: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
                1400: {
                  slidesPerView: 6,
                  spaceBetween: 20,
                },
              }}
              className="mySwiper"
              scrollbar={{ draggable: true }}
              /* onSwiper={(swiper) => console.log(swiper)}
              onSlideChange={() => console.log('slide change')} */
              navigation={{
                nextEl: ".image-swiper-button-next",
                prevEl: ".image-swiper-button-prev",
                disabledClass: "swiper-button-disabled"
              }}
            >
              {listProducts.map((product) => {
              return (
                <SwiperSlide>
                    <BoxTemplateComponent url={product.OfferImageURL} title={product.OfferText} description={product.Detail?.Headline} body={product.Detail?.BodyCopy}/>
                </SwiperSlide>
              );
              })}
              <div className="swiper-button image-swiper-button-next">
                <img className='' src={arrow} alt={"arrow left"} />
              </div>
              <div className="swiper-button image-swiper-button-prev">
                <img className='' src={arrow} alt={"arrow right"} />
              </div>
            </Swiper>
          </div>
        </section>
      </>
    );
  }
  
  export default TopOffers


