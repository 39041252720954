import React, { useEffect, useState } from 'react';
import './../styles/_couponsTableComponent.scss';
import CardCouponComponent from './CardCouponComponent';
import logoTitle01 from "./../images/bag.png";
import logoTitle02 from "./../images/yogurt.png";
import logoTitle03 from "./../images/chicken.png";
import logoTitle04 from "./../images/berries.png";
import logoTitle05 from "./../images/heinz.png";
import logoTitle06 from "./../images/singles.png";
import logoTitle07 from "./../images/digiorno.png";
import logoTitle08 from "./../images/classico.png";
import logoTitle09 from "./../images/mcCormick.png";
import logoTitle10 from "./../images/tide.png";
import logoTitle11 from "./../images/bounty.png";
import logoTitle12 from "./../images/philips.png";
import logoTitle13 from "./../images/paint.png";
import logoTitle14 from "./../images/couch.png";
import logoTitle15 from "./../images/bag_off.png";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import SearchBarComponent from './SearchBarComponent';
import { useUserContext } from "../providers/userProvider";

const CouponsTableComponent = () => {

	let coupons = [
		{
			id: 1,
			image: logoTitle01,
			date: "1/31/24",
			saving: "Save 10%",
			title: 'When you spend $50 or more on',
			description: "groceries",
			url: "https://www.linkedin.com/company/purered/",
			clipped: false
		},
		{
			id: 2,
			image: logoTitle02,
			date: "1/31/24",
			saving: "BOGO FREE",
			title: 'Fage Yogurt',
			description: "Any TWO 6 oz and smaller, mix and match",
			url: "https://www.linkedin.com/company/purered/",
			clipped: false
		},
		{
			id: 3,
			image: logoTitle03,
			date: "1/31/24",
			saving: "10% off",
			title: 'Foster Farms Chicken',
			description: "When you buy TWO, mix & match",
			url: "https://www.linkedin.com/company/purered/",
			clipped: false
		},
		{
			id: 4,
			image: logoTitle04,
			date: "1/31/24",
			saving: "Save $1",
			title: 'Organic Strawberries',
			description: "ONE 10 oz. package",
			url: "https://www.linkedin.com/company/purered/",
			clipped: false
		},
		{
			id: 5,
			image: logoTitle05,
			date: "1/31/24",
			saving: "10% off",
			title: 'Heinz Picnic Pack',
			description: "Limit ONE",
			url: "https://www.linkedin.com/company/purered/",
			clipped: false
		},
		{
			id: 6,
			image: logoTitle06,
			date: "1/31/24",
			saving: "BOGO FREE",
			title: 'Kraft Products',
			description: "Any TWO, mix and match",
			url: "https://www.linkedin.com/company/purered/",
			clipped: false
		},
		{
			id: 7,
			image: logoTitle07,
			date: "1/31/24",
			saving: "Save $1",
			title: 'DiGiorno Pizza',
			description: "Assorted varieties",
			url: "https://www.linkedin.com/company/purered/",
			clipped: false
		},
		{
			id: 8,
			image: logoTitle08,
			date: "1/31/24",
			saving: "10% off",
			title: 'Classico Pasta Sauce',
			description: "Limit ONE",
			url: "https://www.linkedin.com/company/purered/",
			clipped: false
		},
		{
			id: 9,
			image: logoTitle09,
			date: "1/31/24",
			saving: "BOGO FREE",
			title: 'McCormick Grill Mates Seasoning Blends',
			description: "Any TWO, mix and match",
			url: "https://www.linkedin.com/company/purered/",
			clipped: false
		},
		{
			id: 10,
			image: logoTitle10,
			date: "1/31/24",
			saving: "$5 off 2",
			title: 'Tide Liquid Laundry Detergent',
			description: "Any TWO 50 oz or smaller",
			url: "https://www.linkedin.com/company/purered/",
			clipped: false
		},
		{
			id: 11,
			image: logoTitle11,
			date: "1/31/24",
			saving: "BOGO 50% off",
			title: 'Bounty Products',
			description: "Any TWO, mix and match",
			url: "https://www.linkedin.com/company/purered/",
			clipped: false
		},
		{
			id: 12,
			image: logoTitle12,
			date: "1/31/24",
			saving: "Save 50¢",
			title: 'Philips LED Bulb 4-Pack',
			description: "Limit ONE",
			url: "https://www.linkedin.com/company/purered/",
			clipped: false
		},
	];

	const user = useUserContext();
	const [copuponsList, setCouponsList] = useState(coupons);
	const [owncoupons, setOwncoupons] = useState([]);

	const handleLoadMore = () => {
		let moreCoupons = [
			{
				id: 13,
				image: logoTitle13,
				date: "1/31/24",
				saving: "Save $10 when you spend $50",
				title: 'On Behr Paint & Primer',
				description: "Offer valid on select items",
				url: "https://www.linkedin.com/company/purered/",
				clipped: false
			},
			{
				id: 14,
				image: logoTitle14,
				date: "1/31/24",
				saving: "10% off ",
				title: 'Sectional Wicker Patio Furniture Set',
				description: "Limit ONE",
				url: "https://www.linkedin.com/company/purered/",
				clipped: false
			},
			{
				id: 15,
				image: logoTitle15,
				date: "1/31/24",
				saving: "$20 off your next purchase ",
				title: 'When you spend $250 or more on Electronics',
				description: "Offer valid on select items",
				url: "https://www.linkedin.com/company/purered/",
				clipped: false
			},
		];
		setCouponsList(() => [...coupons, ...moreCoupons]);
	}

	function handleToggleProducts(coupon) {
		if (user) {
			let tmpCoupons = [...copuponsList];
			setCouponsList(tmpCoupons);
			copuponsList.find(a => {
				if (a.id === coupon.id) {
					a.clipped ? a.clipped = false : a.clipped = true;
				}
			});
			setTimeout(() => {
				setCouponsList(copuponsList);
			}, 200);
		}
	}

	useEffect(() => {
		const tmpOwn = copuponsList.filter(a => a.clipped === true);
		setOwncoupons(tmpOwn);
		setCouponsList(copuponsList);
	}, [copuponsList]);

	return (
		<>
			<SearchBarComponent />
			<div className='coupons-table-content'>
				{/* <button type="button" raised="" class="btn-filter" id="filterToggleBtn">
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="15" viewBox="0 0 17 15" fill="none">
                <g clip-path="url(#clip0_1669_535)">
                <path d="M0.122738 0.942364C0.330728 0.501174 0.771917 0.220703 1.26038 0.220703H14.8742C15.3627 0.220703 15.8039 0.501174 16.0119 0.942364C16.2199 1.38355 16.1568 1.90353 15.848 2.28169L10.0842 9.32497V13.3303C10.0842 13.7117 9.86988 14.0615 9.52638 14.2316C9.18289 14.4018 8.77636 14.3671 8.47068 14.1371L6.45381 12.6244C6.19855 12.4354 6.05044 12.136 6.05044 11.8177V9.32497L0.283457 2.27854C-0.0222242 1.90353 -0.0884027 1.3804 0.122738 0.942364Z" fill="#192F41"/>
                </g>
                <defs>
                <clipPath id="clip0_1669_535">
                <rect width="16.7647" height="15" fill="white"/>
                </clipPath>
                </defs>
              </svg>
              FILTER
        </button> */}
				{user &&
					<Tabs
						defaultActiveKey="home"
						id="fill-tab-example"
						className="mb-3"
						fill
					>
						<Tab eventKey="home" title={'All coupons (' + copuponsList.length + ')'}>
							<div className='row mt-5'>
								{copuponsList.map((coupon) => {
									return (
										<div className='box col-12 col-md-3 pb-5 gap-5'>
											<CardCouponComponent coupon={coupon} callback={handleToggleProducts} />
										</div>
									);
								})}
								<div className='text-center'>
									{copuponsList.length === 12 &&
										<button onClick={handleLoadMore} type="button" className='button-blue'>Load more</button>
									}
								</div>
							</div>
						</Tab>
						<Tab eventKey="profile" title={'Clipped (' + owncoupons.length + ')'}>
							<div className='row mt-5'>
								{owncoupons.map((coupon) => {
									return (
										<div className='box col-12 col-md-3 pb-5 gap-5'>
											<CardCouponComponent coupon={coupon} callback={handleToggleProducts} />
										</div>
									);
								})}
							</div>
						</Tab>
					</Tabs>
				}
				{!user &&
					<Tabs
						defaultActiveKey="home"
						id="fill-tab-example"
						className="mb-3"
						fill
					>
						<Tab eventKey="home" title={'All coupons (' + copuponsList.length + ')'}>
							<div className='row mt-5'>
								{copuponsList.map((coupon) => {
									return (
										<div className='box col-12 col-md-3 pb-5 gap-5'>
											<CardCouponComponent coupon={coupon} callback={handleToggleProducts} />
										</div>
									);
								})}
								<div className='text-center'>
									{copuponsList.length === 12 &&
										<button onClick={handleLoadMore} type="button" className='button-blue'>Load more</button>
									}
								</div>
							</div>
						</Tab>
					</Tabs>
				}

			</div>
		</>
	)
}

export default CouponsTableComponent