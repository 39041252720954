import React from "react";
import AppRouter from "./router/AppRouter.js";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { UserProvider } from "./providers/userProvider.js";
import "./styles/index.scss";

gsap.registerPlugin(ScrollTrigger);

function App() {
	return (
		<UserProvider>
			<AppRouter />
		</UserProvider>
	);
}

export default App;
