import lottie from "lottie-web";
import { useEffect, useRef } from "react";

const AnimationComponent = ({ animationUrl, animIsPlaying }) => {
	const lottieCont = useRef(null);
	let anim = useRef();

	useEffect(() => {
		if (lottieCont) {
			anim = lottie.loadAnimation({
				container: lottieCont.current,
				renderer: "svg",
				loop: false,
				autoplay: false,
				animationData: animationUrl,
			});
		}
		if (animIsPlaying) {
			anim.play();
		}
		return () => anim.destroy();
	});

	return <div ref={lottieCont}></div>;
};

export default AnimationComponent;
