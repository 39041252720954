import React from 'react';
import './../styles/_boxTemplateComponentButton.scss';

const BoxTemplateComponentButton = ({image, title, description, btnText}) => {

  return (
    <div className='box-link'>
      <div className='box-content'>
        <div className='img-box'>
          <img className='img-cnt' src={image} alt={title} />
        </div>
        <div className='detail-cnt'>
          {title && <h2 className='title-4-l d-flex text-left'>{title}</h2>}
          {description && <p className='large-text'>{description}</p>}
        </div>
        {/* <div className='text-center'>
            <button type="button" className='button-blue'>Shop now</button>
        </div> */}
      </div>
    </div>
  )
}

export default BoxTemplateComponentButton