import { React, useRef, useState } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import AdBurguer from "./../animations/AD1_Burger.json";
import AdPaint from "./../animations/AD2_Paint.json";
import AdElectronics from "./../animations/AD3_Electronics.json";
import BoxTemplateComponent from "../components/BoxTemplateComponent";
import "./../styles/_weeklyAd.scss";

gsap.registerPlugin(ScrollTrigger);

const handleClick = () => {
	window.open("/weekly-ad", "_self");
};

const WeeklyAd = () => {
	const products = [
		{
			title: "BOGO FREE",
			description: "Burgers Were Meant to be Stacked",
			animation: AdBurguer,
			button: "Button",
		},
		{
			title: "BOGO 50% off",
			description: "Quick Home Makeovers",
			animation: AdPaint,
			button: "Button",
		},
		{
			title: "BOGO 50% off",
			description: "Gifts for the Family",
			animation: AdElectronics,
			button: "Button",
		},
	];

	const [animIsPlaying, setAnimIsPlaying] = useState(false);
	const containerRef = useRef();

	useGSAP(
		() => {

			let tl = gsap.timeline({
				scrollTrigger: {
					trigger: ".container",
					start: "-90% top",
					//markers: true,
					//toggleActions: "play none reset reset"
					//onEnterBack: () => ()=>setAnimIsPlaying(true),
				},
			});
			tl.from(".animated-text", {
				autoAlpha: 0,
				y: 20,
				stagger: { each: 0.3 },
			}).from(".animated-box", {
				autoAlpha: 0,
				y: 20,
				stagger: { each: 0.3 },
				onComplete: () => setAnimIsPlaying(true),
			});
			/* , 
		onComplete: () => anim.play()} */
		},
		{ scope: containerRef }
	);

	return (
		Array.isArray(products) && products.length > 0 && (
			<section className="weeklyAd-content" ref={containerRef}>
				<div className="container">
					<div className="row text-center">
						<h2 className="title-2 animated-text">Weekly Ad</h2>
						<p className="large-body text-p animated-text">
							Bring home that freshly-stocked-fridge feeling for a whole lot
							less.
						</p>
					</div>
					<div className="row">
						{products.map((product) =>
							<div className="box col-12 col-md-4 pb-md-0 gap-5 animated-box">
								<BoxTemplateComponent
									key={product.title}
									animation={product.animation}
									animIsPlaying={animIsPlaying}
									description={product.description}
									btnText={product.button}
								/>
							</div>
						)}
					</div>
					<div className="text-center pt-5">
						<button onClick={handleClick} type="button" className="button-blue">
							Shop weekly ad
						</button>
						{/* <Link to="/weekly-ad" className='button-blue'>Grocery</Link> */}
					</div>
				</div>
			</section>
		)
	);
};

export default WeeklyAd;
