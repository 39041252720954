/* import React, { useState } from 'react'; */
import './../styles/_modal.scss';
import emoji from "./../images/emojis.png";


const ModalTemplate = ({ open, onClose, typeModal }) => {
	if (!open) return null
	return (
		<div onClick={onClose} className='overlay'>
			{typeModal === 'welcomePopup' &&
				<div onClick={(e) => {
					e.stopPropagation()
				}} className='modal-cnt col-md-6 col-10'>
					<div className='close-modal'>
						<span onClick={onClose}>
							<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M14.5788 2.57294C15.1343 1.98471 15.1343 1.02941 14.5788 0.441176C14.0232 -0.147059 13.121 -0.147059 12.5654 0.441176L7.88544 5.40118L3.201 0.445882C2.64544 -0.142353 1.74322 -0.142353 1.18766 0.445882C0.632107 1.03412 0.632107 1.98941 1.18766 2.57765L5.87211 7.53294L1.19211 12.4929C0.636552 13.0812 0.636552 14.0365 1.19211 14.6247C1.74766 15.2129 2.64989 15.2129 3.20544 14.6247L7.88544 9.66471L12.5699 14.62C13.1254 15.2082 14.0277 15.2082 14.5832 14.62C15.1388 14.0318 15.1388 13.0765 14.5832 12.4882L9.89877 7.53294L14.5788 2.57294Z" fill="black" fillOpacity="0.6" />
							</svg>
						</span>
					</div>
					<div className='content'>
						<h3 className='title-3-1'>Welcome to Swaggy's!</h3>
						<p className='p-modal mt-2'>Join the <span className='red-spam'>Swaggy's Savings Club</span> today and enjoy special perks
							and exclusive weekly deals delivered to your inbox.</p>
						<p className='p-modal'>Plus, as a new member you'll earn</p>
					</div>
					<div className='content-box'>
						<span className='p-box-firts'>10% OFF </span>
						<span className='p-box-second'>your first order</span>
					</div>
					<div className='btn-container'>
						<button className='button-blue'>
							<span className='bold'>Join the Club</span>
						</button>
					</div>
					<span onClick={onClose} className="underlined-link" style={{ 'cursor': 'pointer' }}>No thanks</span>
				</div>
			}
			{typeModal === 'newsletterPopup' &&
				<div onClick={(e) => {
					e.stopPropagation()
				}} className='modal-cnt col-md-6 col-10'>
					<div className='close-modal'>
						<span onClick={onClose}>
							<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M14.5788 2.57294C15.1343 1.98471 15.1343 1.02941 14.5788 0.441176C14.0232 -0.147059 13.121 -0.147059 12.5654 0.441176L7.88544 5.40118L3.201 0.445882C2.64544 -0.142353 1.74322 -0.142353 1.18766 0.445882C0.632107 1.03412 0.632107 1.98941 1.18766 2.57765L5.87211 7.53294L1.19211 12.4929C0.636552 13.0812 0.636552 14.0365 1.19211 14.6247C1.74766 15.2129 2.64989 15.2129 3.20544 14.6247L7.88544 9.66471L12.5699 14.62C13.1254 15.2082 14.0277 15.2082 14.5832 14.62C15.1388 14.0318 15.1388 13.0765 14.5832 12.4882L9.89877 7.53294L14.5788 2.57294Z" fill="black" fillOpacity="0.6" />
							</svg>
						</span>
					</div>
					<div className='content'>
						<h3 className='firts-title'>SAVE 15% OFF</h3>
						<p className='second-title'>your next purchase</p>
						<p className='p-modal'>Join the <span className='red-spam'>Swaggy's Savings Club</span> today and enjoy special perks
							and exclusive weekly deals delivered to your inbox.</p>
					</div>
					<div className='searchInputModalPopup'>
						<input type="text" placeholder="Enter your email address to join" id="searchInputModalPopup" />
					</div>
					<div className='btn-container'>
						<button className='button-blue'>
							<span className='bold'>Sign Up</span>
						</button>
					</div>
					<span onClick={onClose} className="underlined-link" style={{ 'cursor': 'pointer' }}>No thanks</span>
				</div>
			}
			{typeModal === 'referPopup' &&
				<div onClick={(e) => {
					e.stopPropagation()
				}} className='modal-cnt col-md-6 col-10'>
					<div className='close-modal'>
						<span onClick={onClose}>
							<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M14.5788 2.57294C15.1343 1.98471 15.1343 1.02941 14.5788 0.441176C14.0232 -0.147059 13.121 -0.147059 12.5654 0.441176L7.88544 5.40118L3.201 0.445882C2.64544 -0.142353 1.74322 -0.142353 1.18766 0.445882C0.632107 1.03412 0.632107 1.98941 1.18766 2.57765L5.87211 7.53294L1.19211 12.4929C0.636552 13.0812 0.636552 14.0365 1.19211 14.6247C1.74766 15.2129 2.64989 15.2129 3.20544 14.6247L7.88544 9.66471L12.5699 14.62C13.1254 15.2082 14.0277 15.2082 14.5832 14.62C15.1388 14.0318 15.1388 13.0765 14.5832 12.4882L9.89877 7.53294L14.5788 2.57294Z" fill="black" fillOpacity="0.6" />
							</svg>
						</span>
					</div>
					<div className='content'>
						<p className='refer-firts-title'>Share the Savings!</p>
						<h3 className='refer-second-title'>Refer a Friend and earn <sup className='refer-sub-title'>$</sup>200</h3>
						<p className='p-modal mb-0 p-exit-content'>Send your friend an email and we'll send you $200 when they register for the Swaggy's Savings Club</p>
						<p className='p-modal mt-3'>Have a friend who would enjoy extra savings?</p>
					</div>
					<div className='searchInputModalPopup'>
						<input type="text" placeholder="Enter your friend's email address " id="searchInputModalPopup" />
					</div>
					<div className='btn-container'>
						<button className='button-blue'>
							<span className='bold'>Send</span>
						</button>
					</div>
					<span onClick={onClose} className="underlined-link" style={{ 'cursor': 'pointer' }}>No thanks</span>
				</div>
			}
			{typeModal === 'feedbackSurveryPopup' &&
				<div onClick={(e) => {
					e.stopPropagation()
				}} className='modal-cnt col-md-6 col-10'>
					<div className='close-modal'>
						<span onClick={onClose}>
							<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M14.5788 2.57294C15.1343 1.98471 15.1343 1.02941 14.5788 0.441176C14.0232 -0.147059 13.121 -0.147059 12.5654 0.441176L7.88544 5.40118L3.201 0.445882C2.64544 -0.142353 1.74322 -0.142353 1.18766 0.445882C0.632107 1.03412 0.632107 1.98941 1.18766 2.57765L5.87211 7.53294L1.19211 12.4929C0.636552 13.0812 0.636552 14.0365 1.19211 14.6247C1.74766 15.2129 2.64989 15.2129 3.20544 14.6247L7.88544 9.66471L12.5699 14.62C13.1254 15.2082 14.0277 15.2082 14.5832 14.62C15.1388 14.0318 15.1388 13.0765 14.5832 12.4882L9.89877 7.53294L14.5788 2.57294Z" fill="black" fillOpacity="0.6" />
							</svg>
						</span>
					</div>
					<div className='content'>
						<h3 className='refer-firts-title'>How did we do?</h3>
						<p className='p-modal'>Please rate your overall shopping experience and help us improve!</p>
					</div>
					<div className='img-cnt'>
						<span onClick={onClose}>
							<img className="img h-md-50 h-40" src={emoji} alt="Pure Red Logo" />
						</span>
					</div>
					<div className='content'>
						<p className='p-modal px-3'>Thank you! Your feedback is important to us, and we strive to make each shopping experience the best it can be.</p>
					</div>
					<div className='searchInputModalPopup'>
						<input type="text" placeholder="Enter your email address to join" id="searchInputModalPopup" />
					</div>
					<div className='btn-container'>
						<button className='button-blue'>
							<span className='bold'>Submit</span>
						</button>
					</div>
					<span onClick={onClose} className="underlined-link" style={{ 'cursor': 'pointer' }}>No thanks</span>
				</div>
			}
			{typeModal === 'exitintentPopup' &&
				<div onClick={(e) => {
					e.stopPropagation()
				}} className='modal-cnt col-md-6 col-10'>
					<div className='close-modal'>
						<span onClick={onClose}>
							<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M14.5788 2.57294C15.1343 1.98471 15.1343 1.02941 14.5788 0.441176C14.0232 -0.147059 13.121 -0.147059 12.5654 0.441176L7.88544 5.40118L3.201 0.445882C2.64544 -0.142353 1.74322 -0.142353 1.18766 0.445882C0.632107 1.03412 0.632107 1.98941 1.18766 2.57765L5.87211 7.53294L1.19211 12.4929C0.636552 13.0812 0.636552 14.0365 1.19211 14.6247C1.74766 15.2129 2.64989 15.2129 3.20544 14.6247L7.88544 9.66471L12.5699 14.62C13.1254 15.2082 14.0277 15.2082 14.5832 14.62C15.1388 14.0318 15.1388 13.0765 14.5832 12.4882L9.89877 7.53294L14.5788 2.57294Z" fill="black" fillOpacity="0.6" />
							</svg>
						</span>
					</div>
					<div className='content'>
						<h3 className='title-3-1'>Leaving so soon?</h3>
						<p className='p-modal mt-2 mb-0'>Thanks for stopping by!</p>
						<p className='p-modal mt-0 mb-0 p-exit-content'>If you'd like to stay in touch, join our<span className='red-spam'> Swaggy's Savings Club</span>, and exclusive
							weekly deals delivered to your inbox. Plus, 20% off your first order with us.</p>
					</div>
					<div className='content-box'>
						<span className='p-box-firts'>20% OFF</span>
						<span className='p-box-second'>your first order</span>
					</div>
					<div className='btn-container'>
						<button className='button-blue'>
							<span className='bold'>Join the Club</span>
						</button>
					</div>
					<span onClick={onClose} className="underlined-link" style={{ 'cursor': 'pointer' }}>No thanks</span>
				</div>
			}
		</div>


	)
}

export default ModalTemplate;

