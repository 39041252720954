import React from 'react';
import AnimationComponent from './AnimationComponent';
import './../styles/_boxTemplateComponent.scss';

/* Animation accepts the JSON URL */

const BoxTemplateComponent = ({url, title, description, body, animation, animIsPlaying }) => {
  //console.log(animIsPlaying);
  return (
    <article className='box-content'>
      <div className='img-box'>
        {animation && <AnimationComponent animationUrl={animation} animIsPlaying={animIsPlaying} />}
        {url && <img className='img-cnt' src={url} alt={title} />}
      </div>
      <div className='detail-cnt'>
        <h2 className='text-start one-line-title'>{title}</h2>
        {description ? <p className='large-text'>{description}</p> : null}
        {body ? <p className='large-text'>{body}</p> : null}
      </div>
    </article>
  )
}

export default BoxTemplateComponent