import { React, useRef } from "react";
import './../styles/_trending.scss'
import img1 from "./../images/Social_1.png"
import img2 from "./../images/Social_2.png"
import img3 from "./../images/Social_3.png"
import img4 from "./../images/Social_4.png"
import BoxTemplateComponentButton from '../components/BoxTemplateComponentButton';

//gsap
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const TrendingComponent = () => {


	const products = [
		{
			title: "BOGO FREE",
			description: "Great Unclutter Challenge: Conquering Chaos and Creating Calm",
			image: img1,
			button: "Button"
		},
		{
			title: "BOGO 50% off",
			description: "Dawn Powerwash, fighting stains and doing dishes will never be the same",
			image: img2,
			button: "Button"
		},
		{
			title: "BOGO 50% off",
			description: "DIY your own outdoor oasis",
			image: img3,
			button: "Button"
		},
		{
			title: "BOGO 50% off",
			description: "Untether Your Sound. Unleash Your Soul.",
			image: img4,
			button: "Button"
		}
	];

	const containerRef = useRef();

	useGSAP(
		() => {
			let tl = gsap.timeline({
				scrollTrigger: {
					trigger: ".container",
					start: "-90% top",
					//markers: {startColor: "white", endColor: "white", fontSize: "18px", fontWeight: "bold", indent: 20},
				},
			});
			tl.from(".animated-text", {
				autoAlpha: 0,
				y: 20,
				stagger: { each: 0.3 },
			}).from(".animated-box", {
				autoAlpha: 0,
				y: 20,
				stagger: { each: 0.3 },
			});
			/* , 
		onComplete: () => anim.play()} */
		},
		{ scope: containerRef }
	);

	return (
		Array.isArray(products) &&
		products.length > 0 && (

			<section className='trending-content' ref={containerRef}>
				<div className='container'>
					<div className='row text-center'>
						<h2 className='title-2 animated-text'>Now Trending</h2>
						<p className='large-body text-p animated-text'>Find out what the hype is all about.<br></br>
							Try the latest viral trends at a very swaggy discount.</p>
					</div>
					<div className='row'>
						{products.map((product) => {
							return (
								<div className='box col-6 col-md-4 col-lg-3 pb-md-0 animated-box'>
									<BoxTemplateComponentButton image={product.image} description={product.description} btnText={product.button} />
								</div>
							);
						})}
					</div>
				</div>
			</section>

		)


	)
}

export default TrendingComponent