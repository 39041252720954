import React from 'react';
import Lottie from "lottie-react";
/* import HeroDesktopAnimation from "./../animations/Hero_Banner_Desktop.json"; */
import HeroDesktopAnimationAlt from "./../animations/Hero_Banner_Desktop_Alt.json";
import HeroMobileAnimationAlt from "./../animations/Hero_Banner_Mobile_Alt.json";

/* import heroDesk from './../images/hero.png';
import heroMobile from './../images/Hero_mobile.png'; */

const Hero = () => {

  return (
      <div className='hero'>
        <div className='d-none d-md-block'>
          <Lottie animationData={HeroDesktopAnimationAlt} loop={false} />
        </div>
        {/* <div className='d-none d-md-block w-100'>
          <Lottie animationData={HeroDesktopAnimationAlt} loop={false} />
        </div> */}
        <div className='d-block d-md-none'>
          <Lottie animationData={HeroMobileAnimationAlt} loop={false} />
        </div>
      </div>
  )
}

export default Hero