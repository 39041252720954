import React from 'react';
import './../styles/_cardCouponComponent.scss';

const CardCouponComponent = ({coupon, callback}) => {
  const {image, date, saving, title, description, url, clipped} = coupon;

  return (
    <article className='card-coupon-content'>
      <div className='img-cnt d-flex align-items-center justify-content-center'>
        <img className='' src={image} alt={title} />
      </div>
      <div className='detail-cnt'>
        <p className='small-text small'>Expires {date}</p>
        <h2 className='large-text'>{saving}</h2>
        <h3 className='upper-medium-text'>{title}</h3>
        <p className='upper-medium-text'>{description}</p>
        <br></br>
        {/* <a href={url} className="small-text-link" style={{'cursor': 'pointer'}}>See details</a> */}
        <div className='text-center d-flex justify-content-center gap-5 px-4 mt-5 mb-3'>
          { !clipped ? 
              <button  onClick={() => {callback(coupon);}} 
                type="button" className='button-blue'>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                  <path d="M11.25 17.3025H13.25V13.3025H17.25V11.3025H13.25V7.30249H11.25V11.3025H7.25V13.3025H11.25V17.3025ZM12.25 22.3025C10.8667 22.3025 9.56667 22.04 8.35 21.515C7.13333 20.99 6.075 20.2775 5.175 19.3775C4.275 18.4775 3.5625 17.4192 3.0375 16.2025C2.5125 14.9858 2.25 13.6858 2.25 12.3025C2.25 10.9192 2.5125 9.61916 3.0375 8.40249C3.5625 7.18582 4.275 6.12749 5.175 5.22749C6.075 4.32749 7.13333 3.61499 8.35 3.08999C9.56667 2.56499 10.8667 2.30249 12.25 2.30249C13.6333 2.30249 14.9333 2.56499 16.15 3.08999C17.3667 3.61499 18.425 4.32749 19.325 5.22749C20.225 6.12749 20.9375 7.18582 21.4625 8.40249C21.9875 9.61916 22.25 10.9192 22.25 12.3025C22.25 13.6858 21.9875 14.9858 21.4625 16.2025C20.9375 17.4192 20.225 18.4775 19.325 19.3775C18.425 20.2775 17.3667 20.99 16.15 21.515C14.9333 22.04 13.6333 22.3025 12.25 22.3025ZM12.25 20.3025C14.4833 20.3025 16.375 19.5275 17.925 17.9775C19.475 16.4275 20.25 14.5358 20.25 12.3025C20.25 10.0692 19.475 8.17749 17.925 6.62749C16.375 5.07749 14.4833 4.30249 12.25 4.30249C10.0167 4.30249 8.125 5.07749 6.575 6.62749C5.025 8.17749 4.25 10.0692 4.25 12.3025C4.25 14.5358 5.025 16.4275 6.575 17.9775C8.125 19.5275 10.0167 20.3025 12.25 20.3025Z" fill="white"/>
                </svg>
                Clip
              </button>
            :
            <button onClick={() => {callback(coupon);}} type="button" className='button-blue-white'>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                <path d="M11.35 14.4514L9.2 12.3014C9.01667 12.118 8.78333 12.0264 8.5 12.0264C8.21667 12.0264 7.98333 12.118 7.8 12.3014C7.61667 12.4847 7.525 12.718 7.525 13.0014C7.525 13.2847 7.61667 13.518 7.8 13.7014L10.65 16.5514C10.85 16.7514 11.0833 16.8514 11.35 16.8514C11.6167 16.8514 11.85 16.7514 12.05 16.5514L17.7 10.9014C17.8833 10.718 17.975 10.4847 17.975 10.2014C17.975 9.91803 17.8833 9.6847 17.7 9.50137C17.5167 9.31803 17.2833 9.22637 17 9.22637C16.7167 9.22637 16.4833 9.31803 16.3 9.50137L11.35 14.4514ZM12.75 22.6514C11.3667 22.6514 10.0667 22.3889 8.85 21.8639C7.63333 21.3389 6.575 20.6264 5.675 19.7264C4.775 18.8264 4.0625 17.768 3.5375 16.5514C3.0125 15.3347 2.75 14.0347 2.75 12.6514C2.75 11.268 3.0125 9.96803 3.5375 8.75137C4.0625 7.5347 4.775 6.47637 5.675 5.57637C6.575 4.67637 7.63333 3.96387 8.85 3.43887C10.0667 2.91387 11.3667 2.65137 12.75 2.65137C14.1333 2.65137 15.4333 2.91387 16.65 3.43887C17.8667 3.96387 18.925 4.67637 19.825 5.57637C20.725 6.47637 21.4375 7.5347 21.9625 8.75137C22.4875 9.96803 22.75 11.268 22.75 12.6514C22.75 14.0347 22.4875 15.3347 21.9625 16.5514C21.4375 17.768 20.725 18.8264 19.825 19.7264C18.925 20.6264 17.8667 21.3389 16.65 21.8639C15.4333 22.3889 14.1333 22.6514 12.75 22.6514Z" fill="#192F41"/>
              </svg>
              Clipped
            </button>
          }
        </div>
      </div>
    </article>
  )
}

export default CardCouponComponent