import React from 'react';
import './../styles/_searchBarComponent.scss';
import logoTitle from "./../images/digitalCoupons.png";
import { useUserContext, useUserToggleContext } from "../providers/userProvider";

const SearchBarComponent = () => {
  const user = useUserContext();
  const handleLogin = useUserToggleContext();

  /* console.log('VALIDA', user); */
  return (
    <div className='search-bar-content'>
      <header class="header bg-primary">
        <div class="header-item">
          <div className='py-4 d-flex justify-content-center'>
            <img class="header-logo" style={{maxWidth: '88%', maxHeight: '51px !important', position: 'relative', top: '4px'}} src={logoTitle} alt='Logo title'/>
          </div>
          <div className='login-cnt d-none d-lg-block'>
            <p>
              {!user && 
                <>
                  <span className='text-decoration-underline pointer' onClick={handleLogin}>Log in</span> 
                  <span> to clip up to</span>
                </>
              }
              {user && 
                <span>{user.name}, you have an</span>
              }
            </p>
            <p>{user && <span>estimated </span>}<strong className='number'>{user? user.saving : '$350'}</strong> in savings!</p>
          </div>
        </div>
        <div class="header-item pb-4 pb-lg-0">
          <div class="search">
            <input type="text" placeholder="Search Coupons" id="searchInput" />
            <button type="button" title="search" id="searchIconBtn" style={{display: 'flex'}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <g clip-path="url(#clip0_244_260)">
									<path d="M16.25 8.125C16.25 9.91797 15.668 11.5742 14.6875 12.918L19.6328 17.8672C20.1211 18.3555 20.1211 19.1484 19.6328 19.6367C19.1445 20.125 18.3516 20.125 17.8633 19.6367L12.918 14.6875C11.5742 15.6719 9.91797 16.25 8.125 16.25C3.63672 16.25 0 12.6133 0 8.125C0 3.63672 3.63672 0 8.125 0C12.6133 0 16.25 3.63672 16.25 8.125ZM8.125 13.75C11.2305 13.75 13.75 11.2305 13.75 8.125C13.75 5.01953 11.2305 2.5 8.125 2.5C5.01953 2.5 2.5 5.01953 2.5 8.125C2.5 11.2305 5.01953 13.75 8.125 13.75Z" fill="black" fillOpacity="0.5"></path>
                </g>
                <defs>
                  <clipPath id="clip0_244_260">
                    <rect width="20" height="20" fill="white"></rect>
                  </clipPath>
                </defs>
              </svg>
            </button>
            <div class="searchBox-suggestions-container" id="autoSuggestionsList">

            </div>
          </div>
        </div>
      </header>
      <div className='login-cnt-out d-block d-lg-none'>
        <p>
          {!user && 
            <>
              <span className='text-decoration-underline pointer' onClick={handleLogin}>Log in</span> 
              <span> to clip up to <strong className='number'>$350'</strong> in savings!</span>
            </>
          }
          {user && 
            <>
              <span>{user.name}, you have an</span>
              <p>estimated <strong className='number'>{user.saving}</strong> in savings!</p>
            </>
          }
        </p>
      </div>
    </div>
  )
}

export default SearchBarComponent