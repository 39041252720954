import React, { useEffect, useState } from "react";
import Footer from "../partials/footer";
import Header from "../partials/header";
import Hero from "../components/hero";
import TopOffers from "../components/topOffers";
import WeeklyAd from "../components/weeklyAd";
import Trending from "../partials/trending";
import ModalTemplate from "../components/ModalTemplate";
import SlideModalTemplate from "../components/SlideModalTemplate";
import "../styles/index.scss";

function Index() {
	let lastScrollPosition = 0;
	const [openWelcomeModal, setOpenWelcomeModal] = useState(false);
	//const [seenWelcomeModal, setSeenWelcomeModal] = useState(false);

	const [openNewsModal, setOpenNewsModal] = useState(false);
	const [seenNewsModal, setSeenNewsModal] = useState(false);

	const [openExitModal, setOpenExitModal] = useState(false);
	const [seenExitModal, setSeenExitModal] = useState(false);

	const [showNav, setShowNav] = useState(false);
	const [seenShowNav, setSeenShowNav] = useState(false);

	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {
		document.querySelector("body").addEventListener('mouseleave', bodyMouseLeave);
		window.addEventListener("scroll", windowScroll);

		let countdown = setTimeout(() => {
			if (!isModalOpen && !seenShowNav) {
				setIsModalOpen(true);
				setShowNav(true);
				setSeenShowNav(true);
			}
		}, 8000);

		return () => {
			document.querySelector("body").removeEventListener('mouseleave', bodyMouseLeave);
			window.removeEventListener('scroll', windowScroll);
			clearTimeout(countdown);
		}
	}, [seenNewsModal, seenExitModal, seenShowNav, isModalOpen]);

	let windowScroll = (event) => {
		const scrollPosition = window.scrollY;
		const windowHeight = window.innerHeight;
		const documentHeight = document.body.offsetHeight;

		if (
			scrollPosition > 0.2 * documentHeight &&
			scrollPosition < lastScrollPosition &&
			scrollPosition + windowHeight < documentHeight &&
			!isModalOpen && !seenNewsModal
		) {
			setIsModalOpen(true);
			setOpenNewsModal(true);
			setSeenNewsModal(true);
		}
		lastScrollPosition = scrollPosition;
	}

	let bodyMouseLeave = (event) => {
		if (event.clientY <= 0 && !isModalOpen && !seenExitModal) {
			setIsModalOpen(true);
			setOpenExitModal(true);
			setSeenExitModal(true);
		}
	}

	return (
		<>
			<main>
				<Header></Header>
				<SlideModalTemplate
					show={showNav}
					onClose={() => { setShowNav(false); setIsModalOpen(false); }}
				/>
				<Hero></Hero>
				<WeeklyAd></WeeklyAd>
				<TopOffers></TopOffers>
				<Trending></Trending>
				<Footer></Footer>
			</main>
			<ModalTemplate
				open={openWelcomeModal}
				onClose={() => { setOpenWelcomeModal(false); setIsModalOpen(false); }}
				typeModal={"welcomePopup"}
			/>
			<ModalTemplate
				open={openNewsModal}
				onClose={() => { setOpenNewsModal(false); setIsModalOpen(false); }}
				typeModal={"newsletterPopup"}
			/>
			<ModalTemplate
				open={openExitModal}
				onClose={() => { setOpenExitModal(false); setIsModalOpen(false); }}
				typeModal={"exitintentPopup"}
			/>
		</>
	);
}

export default Index;
