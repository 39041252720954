import { useState, useEffect } from 'react';
import axios from 'axios';

function ProductOffer(productOfferId) {
	const [productOffer, setProductOffer] = useState([]);

	useEffect(() => {
		axios.get('https://pr1-std-demo-public-app-endpoints.azurewebsites.net/Public/Standard/V1/OfferDomains/GetOfferById',
			{
				params: { offerId: productOfferId },
				headers: {
					'XApiKey': 'pgH7QzFHJx4w46fI~5Uzi4RvtTwlEXp1',
				}
			}).then(response => {
				setProductOffer(response.data);
			})
			.catch(error => {
				console.error(error);
			});
	}, [productOfferId]);

	return productOffer;

}

export default ProductOffer;

