import CouponsTableComponent from "../components/CouponsTableComponent";
import Footer from "../partials/footer";
import Header from "../partials/header";

function CouponHub() {
 return (
    <>
      <Header/>
      <CouponsTableComponent/>
      <Footer/>
    </>
  )
}

export default CouponHub;