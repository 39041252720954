import React from "react";
import { Link } from "react-router-dom";

import logo from "./../images/purered-logo-white.svg"
import arrow from "./../images/arrow-in-circle-right-lightblue.svg";


function Footer() {
	return (
		<footer className="footer">
			<div className="container">
				
                    <div className="row pb-4">
                        <div className="col">
                        <a className="title-3" target="_blank" href="https://www.purered.co/contact-us">Questions <br></br>about PR1?<br></br> Let's chat.<img src={arrow} alt=""></img></a>
                        </div>
                    </div>
                    <div className="row">
                        
					<div className="col-12 col-lg-6 col-xxl-6 footer-logo-container reverse ">
					
						<div className="imgs_logo pb-4">
							<img className="me-4" height={"24"} src={logo} alt="Pure Red Logo" />
							<a className="social-link instagram" alt="Instagram Link" href="https://www.instagram.com/pureredmarketing/" target="_blank" rel="noreferrer"></a>
							<a className="social-link linkedin" alt="Linkedin Link" href="https://www.linkedin.com/company/purered/" target="_blank" rel="noreferrer"></a>

						</div>
					</div>
					<div className="col-12 col-lg-3 col-xxl-3 footer-navigation">
						<div className="pt-4 pt-md-0">
							<Link to="/weekly-ad" className="weekly_link"> Weekly Ad</Link>
						</div>
						<div className="row">
							<div className="col-12 d-flex flex-column footer-navigation-box">
								<Link to="/weekly-ad" className="underlined-link">Our Top offers</Link>
								<Link to="/weekly-ad" className="underlined-link">Grocery</Link>
								<Link to="/weekly-ad" className="underlined-link">Home Improvement</Link>
								<Link to="/weekly-ad" className="underlined-link">Electronics</Link>
							</div>
						</div>
					</div>
					<div className="col-12 col-lg-3 col-xxl-3 footer-navigation">
						<div className="pt-4 pt-md-0">
							<Link to="/coupon-hub" className="weekly_link"> Digital Coupons</Link>
						</div>
						<div className="row">
							<div className="col-12 d-flex flex-column footer-navigation-box">
							</div>
							{/* <div className="col-12 col-md-7 d-flex flex-column footer-navigation-box">
                                </div> */}
						</div>
					</div>
				    </div>

				<div className="line"></div>
				<div className="row d-flex flex-column-reverse flex-md-row py-4">
					<div className="col-12 col-md-4 text-center text-md-start pt-3 pt-md-0">
						<span className="small-text reverse">
							©{new Date().getFullYear()} PureRed All Rights Reserved.
						</span>
					</div>
					<div className="col-12 col-md-8 d-flex justify-content-center align-items-center justify-content-md-end">
						<div className="d-flex justify-content-end gap-5 mx-md-5">
							<a className="small-text-link reverse no-underlined-link" href="https://www.purered.co/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>
							<a className="small-text-link reverse no-underlined-link" href="https://www.purered.co/cookie-policy" target="_blank" rel="noreferrer">Cookie Policy</a>
							<a className="small-text-link reverse no-underlined-link" href="https://www.purered.co/terms-of-use" target="_blank" rel="noreferrer">Terms of Use</a>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;